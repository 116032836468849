import React, {useState} from 'react';
import './App.css';

// Import Tools
import {Routes, Route, BrowserRouter} from 'react-router-dom';

// Import Components
import Nav from './components/Nav';
import Home from './Home/Home';
import Footer from './components/Footer/Footer';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from './Contact/Contact';


function App() {
    const [activeColor, setActiveColor] = useState('white');
    return (
        <BrowserRouter>
            <Nav fontColor={activeColor}/>
            <Routes>
                <Route path="/" exact element={<Home setActiveColor={setActiveColor} fontColor={activeColor}/>}/>
                {/*<Route path="/projects" element={<ProjectsPath/>}/>*/}
                <Route path="/contact" exact element={<Contact/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
