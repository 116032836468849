import React from 'react'

const Line = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center"}}>
      <div style={{height: "5px", width: "200px", backgroundColor: "#333", borderRadius: "5px"}}>

      </div>
    </div>
  )
}

export default Line
