import React, {useEffect, useState} from 'react';
import './Technologies.css';
import axios from "axios";
import Line from "../components/Line"; // Add your own CSS for styling

const Technologies = () => {
    const [logos, setLogos] = useState([]);

    useEffect( () => {
        axios.get('/logoData.json')
            .then(response => {
                setLogos(response.data);
            })
            .catch(error => {
                console.error('Error fetching logo data:', error);
            });
    }, []);

    useEffect(() => {
        const handleScroll = (e) => {
            e.preventDefault();
        };

        document.querySelector('.skills-container').addEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className="skills-container">
            <h2 className="skills-heading">Technologies I Work With</h2>
            <Line />
            <div className="skills-cards">
                {logos.map((tech, index) => (
                    <div className="skill-card" key={index}>
                        <img src={tech.logoUrl} alt={`${tech.name} logo`} className="skill-logo"/>
                        <p className="skill-name">{tech.name}</p>
                    </div>
                ))}
            </div>
            <h2 className="skills-heading">More about me</h2>
            <Line />
            <div className="more-about-bio">
                In my day-to-day work and in showcasing my portfolio, I rely on a combination of powerful tools and smart practices. With the help of AI tools like ChatGPT and GitHub Copilot, I infuse a touch of modern magic into my projects. Following the 12-factor approach, I ensure that my apps are well-organized, scalable, and easy to work with. I maintain clean and organized code, making it straightforward for others to understand and collaborate. Testing plays a pivotal role in my routine; I conduct thorough unit tests for individual components and API tests to validate interactions within the software. Every line of code is scrutinized to guarantee safety, efficiency, and functionality. Guided by SOLID principles, I adhere to a set of timeless rules for building top-notch software. Utilizing platforms like GitHub and GitLab, I streamline the development process, ensuring smooth transitions from code creation to user deployment.
            </div>
        </div>
    );
};

export default Technologies;
