import React, { useState } from 'react'
import './Footer.css'

function Footer() {

  let time = new Date().toLocaleTimeString();
  const [currentTime, setCurrentTime] = useState(time);
  const [year, setYear] = useState(new Date().getFullYear());

  const UpdateTime = () => {
    let time = new Date().toLocaleTimeString();
    let year =  new Date().getFullYear();
    setCurrentTime(time);
    setYear(year);
  };

  setInterval(UpdateTime, 1000);

  return (
    <div>
      <footer className='footer'>
        <div className="footer-content">
          <h2 className="licence">Europe © {year}</h2>
          <div className="social-icons">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <a href="mailto:kovats.kornel95@gmail.com" ><i className="fa fa-envelope" ></i></a>
            <a href="https://www.facebook.com/kornel.kovats.777/" target='_blank' rel="noreferrer"><i className="fa fa-facebook"></i></a>
            <a href="https://www.instagram.com/kornel_kovats" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
            <a href="https://wa.me/+36706125416" target="_blank" rel="noreferrer" ><i className="fa fa-whatsapp"></i></a>
          </div>
          <h2 className="time">{currentTime}</h2>
        </div>
      </footer>
    </div>
  )
}

export default Footer;




