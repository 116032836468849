import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Nav.css';
import Profpic from '../Assets/image/profile-picture.jpg';

function Nav({fontColor}) {
    const [open, setOpen] = useState(false)
    const handleClick = (e) => {
        if (e.target.classList.contains('side-backdrop')) {
            setOpen(false);
        }
    }
    const onLinkHandle = () => {
        setOpen(false)
    }
    return (
        <>
            <div>
                <div className={open ? "menu-btn open" : "menu-btn"}
                     onClick={() => setOpen(!open)}>
                    <div className="menu-btn_burger"></div>
                </div>
                <div className={open ? "side-backdrop active" : "side-backdrop"} onClick={handleClick}></div>
                <aside className={open ? "nav-sidebar active" : "nav-sidebar"}>
                    <div className="sidebar-wrapper">
                        <ul className="list-of-menu">
                            <li onClick={onLinkHandle}><Link to="/" className="menulinks">Home</Link></li>
                            {/*<li onClick={onLinkHandle}><Link to="/projects"*/}
                            {/*                                 className="menulinks">Projects</Link></li>*/}
                            <li onClick={onLinkHandle}><Link to="/contact" className="menulinks">Contact</Link></li>
                        </ul>
                        <div className="profile">
                            <div className="profile-wrapper">
                                <div className="profilePic">
                                    <img src={Profpic} alt="NavPic" width="auto" height="auto"/>
                                </div>
                                <div className="profileBio">
                                    <h1>Hello !</h1>
                                    <p>Welcome to my Portfolio! This Website briefly present what I'm doing. You can
                                        find details about web development and my professional skills.</p>
                                </div>
                                <div className="contactinfo">
                                    <div>
                                        <h1>Contact</h1>
                                        <div className="profile-social-wrapper">
                                            <div className="profile-social">
                                                <a href="mailto:kovats.kornel95@gmail.com"><i
                                                    className="fa fa-envelope"/></a>
                                                <a href="https://www.linkedin.com/in/kornél-kováts-814b30162"
                                                   target="_blank" rel="noopener noreferrer"><i
                                                    className="fa fa-linkedin" aria-hidden="true"></i>
                                                </a>
                                                <a href="https://wa.me/+36706125416"
                                                   target="_blank" rel="noopener noreferrer"><i
                                                    className="fa fa-whatsapp" aria-hidden="true"/> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h1>Social Media</h1>
                                        <div className="profile-social-wrapper">
                                            <div className="profile-social">
                                                <a href="https://facebook.com/kornel.kovats.777/" target="_blank"
                                                   rel="noopener noreferrer"><i className="fa fa-facebook"
                                                                                aria-hidden="true"></i> </a>
                                                <a href="https://instagram.com/kornel_kovats/" target="_blank"
                                                   rel="noopener noreferrer"><i className="fa fa-instagram"
                                                                                aria-hidden="true"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            <Link to="/" className='logo-name'>
                <h3 className='firstname' style={{ color: fontColor }}>Kornel </h3><h3 className='surname' style={{ color: fontColor }}>Kovats</h3>
            </Link>
        </>

    );
}

export default Nav;
