import React from 'react'
import './Contact.css'

import InstaImg from '../Assets/image/aboutProf.jpg'

function Contact() {
    return (
        <>
            <div className="contact-page">
                <div className="contact-modal">
                    <section className="contact-title">
                        <img src={InstaImg} alt=""/>
                        <div>
                            <a href="https://instagram.com/kornel_kovats/" target="_blank"
                               rel="noopener noreferrer">@kornel_kovats</a>
                        </div>
                    </section>

                    <section className="contact-links">
                        <h3>Reach me at</h3>
                        <a href="mailto:kovats.kornel95@gmail.com" target="_blank" rel="noopener noreferrer"><i
                            className="fa fa-envelope" aria-hidden="true"></i> Email</a>
                        <a href="https://www.linkedin.com/in/kornél-kováts-814b30162" target="_blank"
                           rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i> LinkedIn </a>
                        <a href="https://wa.me/+36706125416" target="_blank"
                           rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i> WhatsApp </a>
                        <a href="https://github.com/kornelkovats" target="_blank" rel="noopener noreferrer"><i
                            className="fa fa-github" aria-hidden="true"></i> GitHub </a>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Contact
