import React, {useEffect, useState} from 'react';
import './Home.css';
import {Link} from 'react-router-dom';
// Images
import Speed from '../Assets/icons/speed.png';
import Responsive from '../Assets/icons/responsive.png';
import Dynamic from '../Assets/icons/dynamic.png';
import AboutProf from '../Assets/image/aboutProfMain.jpg';
// Components
import Line from '../components/Line';
import Technologies from "../Technologies/Technologies";

function Home({setActiveColor}) {
     const [webdevHeroHeight, setWebdevHeroHeight] = useState(0);

    const handleDownload = () => {
        const pdfUrl = process.env.PUBLIC_URL + '/Kornel_Kovats_CV.pdf';
        window.open(pdfUrl, '_blank');
    };

    useEffect(() => {
        const listenScrollEvent = e => {
            if (window.scrollY > webdevHeroHeight) {
                setActiveColor('black')
            } else {
                setActiveColor('white')
            }
        }
        const webdevHeroElement = document.querySelector('.webdev-hero');
        if (webdevHeroElement) {
            setWebdevHeroHeight(webdevHeroElement.getBoundingClientRect().height);
            // console.log('Height of webdev-hero div:', webdevHeroHeight);
        }
        window.addEventListener('scroll', listenScrollEvent)
    }, [setActiveColor, webdevHeroHeight]);

    return (
        <div className="webdev-body">
            <section className="webdev-hero">
                <div className="webdev-left">
                    <h2>Hi, I'm
                        <br/> Kornel Kovats.</h2>
                </div>
                <div className="webdev-right">
                    <h3>A Full-Stack Software Engineer.</h3>
                </div>
            </section>
            <section className="webdev-about" id="about">
                <div className="about-content-wrapper">
                    {/*<div className={`header ${isSticky ? 'sticky' : ''}`}>*/}
                    <div className="about-info-wrapper">
                        <div className="about-img-holder">
                            <img src={AboutProf} alt=""/>
                        </div>
                        <div className="about-bio">
                            <h2>Who am I?</h2>
                            <p>I'm a Full-Stack Software Engineer from Hungary.
                                <br/>I have high passion to create fast and scalable applications. Combined with great
                                solutions.
                                <br/>
                                <br/><Link to="/Contact" className="about-bio-link"> Let's create something
                                    special. </Link></p>
                            <button className="download-button" onClick={handleDownload}>Download Resume</button>
                        </div>
                    </div>
                </div>
            </section>
            <div className="about-title">
                <h2>About</h2>
            </div>
            <Line/>

            <div className="skills-wrapper">
                <div className="skill-info-wrapper">
                    <div className="skill-image">
                        <img src={Speed} alt=""/>
                    </div>
                    <h2>Fast</h2>
                    <p>Scaled on fast load times and a great user experience.</p>
                </div>

                <div className="skill-info-wrapper">
                    <div className="skill-image">
                        <img src={Responsive} alt=""/>
                    </div>
                    <h2>Responsive</h2>
                    <p>Scaled on fast load times and a great user experience.</p>
                </div>

                <div className="skill-info-wrapper">
                    <div className="skill-image">
                        <img src={Dynamic} alt=""/>
                    </div>
                    <h2>Dynamic</h2>
                    <p>Scaled on fast load times and a great user experience.</p>
                </div>
            </div>
            <Technologies/>
        </div>
    );
}

export default Home;
